// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/XVUmpmPn1EPL0dzocT35/Ticker.js";
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["aAkEvyvWe"];

const serializationHash = "framer-Gkr8K"

const variantClassNames = {aAkEvyvWe: "framer-v-icrq48"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, RKOat1DQr: title ?? props.RKOat1DQr ?? "LARY "} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RKOat1DQr, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aAkEvyvWe", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-icrq48", className, classNames)} data-framer-name={"Lary"} layoutDependency={layoutDependency} layoutId={"aAkEvyvWe"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1qxjjep-container"} layoutDependency={layoutDependency} layoutId={"auoHFrBmI-container"}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: true, fadeInset: 0, fadeWidth: 0, overflow: false}} gap={0} height={"100%"} hoverFactor={1} id={"auoHFrBmI"} layoutId={"auoHFrBmI"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} sizingOptions={{heightType: true, widthType: true}} slots={[<motion.div className={"framer-1hcpstp"} data-framer-name={"Name"} layoutDependency={layoutDependency} layoutId={"y_nv6m1iB"}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "R0Y7UHVibGljIFNhbnMtOTAw", "--framer-font-family": "\"Public Sans\", sans-serif", "--framer-font-size": "240px", "--framer-font-weight": "900", "--framer-text-color": "var(--extracted-gdpscs, var(--token-1dfb5f8e-6d59-4a6a-bbc6-917bc568117f, rgb(0, 0, 0)))"}}>LARY </motion.h1></React.Fragment>} className={"framer-1wvgu72"} fonts={["GF;Public Sans-900"]} layoutDependency={layoutDependency} layoutId={"K13YxQs1j"} style={{"--extracted-gdpscs": "var(--token-1dfb5f8e-6d59-4a6a-bbc6-917bc568117f, rgb(0, 0, 0))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={RKOat1DQr} verticalAlignment={"top"} withExternalLayout/></motion.div>]} speed={60} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Gkr8K.framer-1r0et65, .framer-Gkr8K .framer-1r0et65 { display: block; }", ".framer-Gkr8K.framer-icrq48 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 288px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1894px; }", ".framer-Gkr8K .framer-1qxjjep-container { flex: none; height: 432px; position: relative; width: 1894px; z-index: 1; }", ".framer-Gkr8K .framer-1hcpstp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-Gkr8K .framer-1wvgu72 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Gkr8K.framer-icrq48, .framer-Gkr8K .framer-1hcpstp { gap: 0px; } .framer-Gkr8K.framer-icrq48 > *, .framer-Gkr8K .framer-1hcpstp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Gkr8K.framer-icrq48 > :first-child, .framer-Gkr8K .framer-1hcpstp > :first-child { margin-left: 0px; } .framer-Gkr8K.framer-icrq48 > :last-child, .framer-Gkr8K .framer-1hcpstp > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 288
 * @framerIntrinsicWidth 1894
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"RKOat1DQr":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrEXB_BAtk: React.ComponentType<Props> = withCSS(Component, css, "framer-Gkr8K") as typeof Component;
export default FramerrEXB_BAtk;

FramerrEXB_BAtk.displayName = "Hero Ticker";

FramerrEXB_BAtk.defaultProps = {height: 288, width: 1894};

addPropertyControls(FramerrEXB_BAtk, {RKOat1DQr: {defaultValue: "LARY ", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerrEXB_BAtk, [{explicitInter: true, fonts: [{family: "Public Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuv565xg0pX189fg.woff2", weight: "900"}]}, ...TickerFonts], {supportsExplicitInterCodegen: true})